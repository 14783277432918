
// Libraries
import * as React from 'react'
import _ from "lodash"

// Services
import ApiService from "../../../services/api"

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminEventsTableHeader from '../../../components/admin/events/adminEventsTableHeader'
import AdminEventsTable from '../../../components/admin/events/adminEventsTable'
import {faThumbtack} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminEventsFeaturedPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {}
		},
		events: [],
		emptyMessage: "Retrieving event data ..."
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, events, emptyMessage} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table nav-blue-half" hideSideNav>
				<Seo title="Events Management" />
				<div className="admin-table__wrap">
					<AdminEventsTableHeader
						titleText="Select Featured Events"
						infoText="These events will be highlighted in the homepage and in the event page for all carers"
						titleIcon={faThumbtack}
						searchValue={activeFilters.search}
						searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<AdminEventsTable
						events={events}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView}
						updateStateEvents={this.updateStateEvents}
						layoutFeatured={true} />
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		Promise.all([
			this.getEvents(activeFilters, false)
				.catch(() => addNotification('There was an error fetching the events.', 'error'))
		]).then(() => {
			this.setState({
				emptyMessage: "No events found, try adjusting your filters"
			})
		})
	}

	getEvents = (queryParams) => {
		const apiService = new ApiService()
		queryParams['sort'] = '-featured,starts'
		queryParams['filter']['status_id'] = 1
		return apiService.get('events', {}, queryParams).then( (response) => {
			this.setState({events: response.data})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		this.setState({activeFilters}, () => {
			this.getEvents(activeFilters)
				.catch(() => addNotification('There was an error filtering the events.', 'error'))
		})
	}

	updateStateEvents = (event) => {
		const {events} = this.state

		events.forEach((existingEvent, key) => {
			if (existingEvent.id === event.id) {
				events[key] = {...existingEvent, ...event}
			}
		})

		this.setState({
			events: events
		})
	}
}

export default AdminEventsFeaturedPage
